:root {
    --modal-scroll-bg :#F3F3F3;
    --modal-scroll-bar :#C8C8C8;
}

.modal-open .modal {
    overflow-x:hidden;
    overflow-y:auto;
}
.modal {
    position:fixed;
    top:0;
    left:0;
    z-index:1050;
    display:none;
    width:100%;
    height:100%;
    overflow:hidden;
    outline:0;
    background-color:rgba(0,0,0,0.8);
}
.modal-dialog {
    position:relative;
    width:auto;
    pointer-events:none;
    margin:.5rem;
}
.modal.fade .modal-dialog {
    transition:transform .3s ease-out,-webkit-transform .3s ease-out;
    -webkit-transform:translate(0,-50px);
    transform:translate(0,-50px);
}
.modal.show .modal-dialog {
    -webkit-transform:none;
    transform:none;
}
.modal.modal-static .modal-dialog {
    -webkit-transform:scale(1.02);
    transform:scale(1.02);
}
.modal-dialog-scrollable {
    display:flex;
    max-height:calc(100%-1rem);
}
.modal-dialog-scrollable .modal-content {
    max-height:calc(100vh-1rem);
    overflow:hidden;
}
.modal-dialog-scrollable .modal-footer,.modal-dialog-scrollable .modal-header {
    -ms-flex-negative:0;
    flex-shrink:0;
}
.modal-dialog-scrollable .modal-body {
    overflow-y:auto;
}
.modal-dialog-centered {
    display:flex;
    -ms-flex-align:center;
    align-items:center;
    min-height:calc(100%-1rem);
}
.modal-dialog-centered::before {
    display:block;
    height:min-content;
    content:"";
}
.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction:column;
    flex-direction:column;
    -ms-flex-pack:center;
    justify-content:center;
    height:100%;
}
/* .modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height:none;
} */
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    margin-top:-7%;
    max-height:90%;
} 
.modal-dialog-centered.modal-dialog-scrollable::before {
    content:none;
}
.modal-content {
    position:relative;
    display:flex;
    -ms-flex-direction:column;
    flex-direction:column;
    width:100%;
    pointer-events:auto;
    background-color:#fff;
    background-clip:padding-box;
    border:1px solid rgba(0,0,0,.2);
    outline:0;
}
.modal-backdrop {
    position:fixed;
    top:0;
    left:0;
    z-index:1040;
    width:100vw;
    height:100vh;
    background-color:#000;
}
.modal-backdrop.show {
    opacity:.5;
}
.modal-header {
    display:flex;
    -ms-flex-align:start;
    align-items:flex-start;
    -ms-flex-pack:justify;
    justify-content:space-between;
    padding:2rem 2rem 1rem 2rem;
}
.modal-title {
    margin-bottom:0;
    line-height:1.5;
    font-size:2.2rem;
    font-weight:300;
}
.modal a.modal-close {
    color: #838383;
    font-size: 35px;
    padding:0;
    margin-top:5px;
}
.modal a.modal-close:hover {
    color: #8A8A8A;
}
.modal-body {
    position:relative;
    -ms-flex:1 1 auto;
    flex:1 1 auto;
    padding:0 2rem 2rem 2rem;
}
.modal-body::-webkit-scrollbar {
    width:5px;
}
.modal-body::-webkit-scrollbar-thumb {
    background-color: var(--modal-scroll-bar);
}
.modal-body::-webkit-scrollbar-track {
    background-color: var(--modal-scroll-bg);
}

.modal-footer {
    display:flex;
    -ms-flex-wrap:wrap;
    flex-wrap:wrap;
    -ms-flex-align:center;
    align-items:center;
    -ms-flex-pack:end;
    justify-content:flex-end;
    border-top:1px solid #dee2e6;
    padding:.75rem;
}
.modal-footer>* {
    margin:.25rem;
}
.modal-scrollbar-measure {
    position:absolute;
    top:-9999px;
    width:50px;
    height:50px;
    overflow:scroll;
}
.fade:not(.show),.modal-backdrop.fade {
    opacity:0;
}
.accordion>.card,.modal-open {
    overflow:hidden;
}
.modal-dialog {
    max-width:500px;
    margin:1.75rem auto;
}
.modal-dialog-scrollable {
    max-height:calc(100%-3.5rem);
}
.modal-dialog-scrollable .modal-content {
    max-height:calc(100vh-3.5rem);
}
.modal-dialog-centered {
    min-height:calc(100%-3.5rem);
}
.modal-dialog-centered::before {
    height:min-content;
}
.modal-sm {
    max-width:300px;
}
.modal-lg,.modal-xl {
    max-width:800px;
}
.container,.container,.container-lg,.container-md,.container-sm,.container-xl,.modal-xl {
    max-width:1140px;
}

.form-control,.btn,.fade,.collapsing,.custom-switch .custom-control-label::after,.custom-control-label::before,.custom-file-label,.custom-select,.badge,.progress-bar,.modal.fade .modal-dialog,.carousel-item,.carousel-fade .active.carousel-item-left,.carousel-fade .active.carousel-item-right,.carousel-control-next,.carousel-control-prev,.carousel-indicators li {
    transition:none;
}